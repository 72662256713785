import { errorCallback, makeUrl } from "../../const/const";
import { MAKE_PRODUCT_REQUEST, SEARCH_PRODUCT_REQUESTS, getFetchRequest } from "../../store/requests";

export const GET_IMAGE_TIMEOUT = 200;

export const hintsService = {
    searchInputValue: '',
    listOfHints: [],
    globalSearchHistory: [],
    observersList: [],
    globalHistoryObservers: [], // Добавляем список наблюдателей для глобальной истории
    lastActionTime: '',
    searchTimeout: null,
    MAX_HISTORY: 4,
    SEARCH_HISTORY_KEY: "searchHistory",

    setSearchValue(searchValue) {
        this.searchInputValue = searchValue;
        this.lastActionTime = Date.now();
        clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(() => {
            this.makeHintsSearch();
        }, 300);
    },

    getSearchValue() {
        return this.searchInputValue;
    },

    makeHintsSearch() {
        try {
            if (this.searchInputValue.length > 2) {
                console.log(this.searchInputValue);
                getFetchRequest(makeUrl([SEARCH_PRODUCT_REQUESTS, `hint/?q=${this.searchInputValue}`]), 
                    (response) => {
                        this.listOfHints = response.slice(0, 5);
                        this.notifyHintsObservers();
                    }, errorCallback);
            }
        } catch (err) {
            console.log(err);
        }
    },

    fetchGlobalSearchHistory() {
        getFetchRequest(makeUrl(['/api/v1/product/search/history']), 
            (response) => {
                this.globalSearchHistory = response;
                this.notifyGlobalHistoryObservers(); // Уведомляем наблюдателей о новом списке
            }, errorCallback);
    },

    storeSearchQuery(query) {
        return new Promise((resolve, reject) => {
            const encodedQuery = encodeURIComponent(query);
            const url = makeUrl([`/api/v1/product/search/history/store?query=${encodedQuery}`]);

            getFetchRequest(url, 
                () => {
                    resolve();  // Завершаем промис после успешного запроса
                },
                (error) => {
                    errorCallback(error);
                    reject(error);  // Отклоняем промис в случае ошибки
                },
                true
            );
        });
    },

    saveSearchHistory(query) {
        return new Promise((resolve) => {
            if (!query || query.length < 3) return resolve();  // Завершаем промис, если нечего сохранять

            let history = this.getSearchHistory();
            history = [query, ...history.filter(item => item !== query)];
            if (history.length > this.MAX_HISTORY) {
                history = history.slice(0, this.MAX_HISTORY);
            }

            localStorage.setItem(this.SEARCH_HISTORY_KEY, JSON.stringify(history));
            resolve();  // Завершаем промис сразу после сохранения в localStorage
        });
    },
    getSearchHistory() {
        return JSON.parse(localStorage.getItem(this.SEARCH_HISTORY_KEY)) || [];
    },

    registerHintsObserver(observer) {
        this.observersList.push(observer);
    },

    unRegisterHintsObserver(observer) {
        this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
    },

    notifyHintsObservers() {
        this.observersList.forEach(observer => observer(this.listOfHints));
    },

    registerGlobalHistoryObserver(observer) {
        this.globalHistoryObservers.push(observer); // Добавляем нового наблюдателя для глобальной истории
    },

    unRegisterGlobalHistoryObserver(observer) {
        this.globalHistoryObservers = this.globalHistoryObservers.filter(observerItem => observerItem !== observer);
    },

    notifyGlobalHistoryObservers() {
        this.globalHistoryObservers.forEach(observer => observer(this.globalSearchHistory)); // Уведомляем всех наблюдателей глобальной истории
    },

    getHints() {
        return this.listOfHints;
    },

    getGlobalSearchHistory() {
        return this.globalSearchHistory;
    },

    changeLastActionTime(lastActionTime) {
        this.lastActionTime = lastActionTime;
    },

    getHintImage(productId, callback) {
        if ((Date.now() - this.lastActionTime) > GET_IMAGE_TIMEOUT) {
            getFetchRequest(makeUrl([MAKE_PRODUCT_REQUEST, '/', productId, '/?onlyImage=true']), 
                (response) => {
                    const currentProductIndex = this.listOfHints.findIndex(it => it.productId === productId);
                    this.listOfHints[currentProductIndex].images = response;
                    callback(response);
                }, errorCallback);
        }
    },

    clearHintsList() {
        this.listOfHints = [];
        this.notifyHintsObservers();
    },
};
